<template>
    <base-button
        v-bind="button_props"
        v-on="$listeners"
        :class="{ waiting }"
    >
        <loader
            v-if="waiting"
            :size="loaderSize"
            :color="loader_color"
        />
        <span :class="{ 'd-invisible': waiting }"><slot></slot></span>
    </base-button>
</template>

<script>
import BaseButton from "./BaseButton"
import Loader from "@/custom/components/Loader"

export default {
    name: "DefaultButton",
    components: {
        BaseButton,
        Loader
    },
    computed: {
        button_props () {
            const { waiting, ...button_props } = this.$props
            return button_props
        },
        loader_color () {
            if(this.color.match(/^(primary|secondary|error)$/)) return "light"
            if(this.color.match(/^(warning)$/)) return "gray"
            return "dark"
        }
    },
    props: {
        label: {
            type: String,
            validator: prop => !!prop
        },
        type: {
            type: String,
            default: "button"
        },
        flavor: {
            type: String,
            validator: prop => !prop || prop.match(/^(link|normal)$/i),
            default: "normal"
        },
        color: {
            type: String,
            validator: prop => !prop || prop.match(/^(primary|secondary|regular|error|warning|success|clear|light)$/i),
            default: "regular"
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm|md|lg)$/i),
            default: "md"
        },
        block: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        waiting: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loaderSize: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm)$/i),
            default: "sm"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

button {
    position: relative;

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.btn-primary {
        .loader {
            & > div {
                background: $light-color;
            }
        }
    }
}
</style>
