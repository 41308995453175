<template>
    <div
        :class="{[`calendar-${size}`]: true}"
        class="calendar"
    >
        <div class="calendar-nav navbar">
            <base-button
                flavor="link"
                size="lg"
                class="btn-action"
                tabindex="-1"
                @click.prevent="previous_month"
            >
                <open-icon
                    glyph="angle-left"
                    :title="$root.translate('Previous month')"
                />
            </base-button>
            <div
                v-if="current_page_timestamp"
                class="navbar-primary"
            >
                {{ current_page_date | nibnut.date("MMM YYYY") }}
            </div>
            <base-button
                flavor="link"
                size="lg"
                class="btn-action"
                tabindex="-1"
                @click.prevent="next_month"
            >
                <open-icon
                    glyph="angle-right"
                    :title="$root.translate('Next month')"
                />
            </base-button>
        </div>

        <div class="calendar-container">
            <div class="calendar-header">
                <div
                    v-for="weekday in weekdays"
                    :key="weekday"
                    class="calendar-date"
                >
                    {{ weekday }}
                </div>
            </div>

            <div class="calendar-body">
                <div
                    v-for="grid_data in grid"
                    :key="grid_data.id"
                    :class="grid_data.cell_classes"
                    class="calendar-date text-center"
                >
                    <base-button
                        :disabled="grid_data.disabled"
                        :class="grid_data.button_classes"
                        class="date-item"
                        tabindex="-1"
                        @click.prevent="click(grid_data.date)"
                    >
                        {{ grid_data.date | nibnut.date("D") }}
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import handles_dates from "@/nibnut/mixins/HandlesDates"

import BaseButton from "@/nibnut/components/Buttons/BaseButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "BaseCalendar",
    mixins: [handles_dates],
    components: {
        BaseButton,
        OpenIcon
    },
    mounted () {
        this.reset()
    },
    watch: {
        selection: "reset"
    },
    methods: {
        reset () {
            let first_selected_date
            if(!this.selection) first_selected_date = this.$dayjs().startOf("day")
            else if(Array.isArray(this.selection)) first_selected_date = this.standardized_range_object(this.selection[0]).from
            else first_selected_date = this.standardized_range_object(this.selection).from
            this.current_page_timestamp = this.$dayjs(first_selected_date).startOf("month").valueOf()
        },
        previous_month () {
            this.current_page_timestamp = this.$dayjs(this.current_page_date).subtract(1, "month").valueOf()
        },
        next_month () {
            this.current_page_timestamp = this.$dayjs(this.current_page_date).add(1, "month").valueOf()
        },
        click (date) {
            this.$emit("click", date)
        }
    },
    computed: {
        current_page_date () {
            return this.$dayjs.unix(this.current_page_timestamp / 1000)
        },
        weekdays () {
            return [
                this.$root.translate("Sun"),
                this.$root.translate("Mon"),
                this.$root.translate("Tue"),
                this.$root.translate("Wed"),
                this.$root.translate("Thu"),
                this.$root.translate("Fri"),
                this.$root.translate("Sat")
            ]
        },
        grid () {
            const today = this.$dayjs().startOf("day")
            const min = this.standardized_date(this.min)
            const max = this.standardized_date(this.max)
            let selection = this.selection
            if(selection && !Array.isArray(selection)) selection = [selection]

            const dates = []
            if(this.current_page_timestamp) {
                let date = this.current_page_date.subtract(this.current_page_date.date(), "days")
                const current_month = this.current_page_date.month()

                for(let loop = 0; loop < 35; loop++) {
                    const month = date.month()
                    const cell_classes = {}
                    if((month < current_month) || ((month === 11) && (current_month === 0))) cell_classes["prev-month"] = true
                    else if((month > current_month) || ((month === 0) && (current_month === 12))) cell_classes["next-month"] = true

                    if(selection) {
                        selection.forEach(selected_date => {
                            const range = this.standardized_range_object(selected_date)
                            if(date.isSame(range.from) || (date.isAfter(range.from) && date.isBefore(range.to))) {
                                if(!cell_classes["calendar-range"]) cell_classes["calendar-range"] = true
                                if(!cell_classes["range-start"] && date.isSame(range.from, "day")) cell_classes["range-start"] = true
                                if(!cell_classes["range-end"] && date.isSame(range.to, "day")) cell_classes["range-end"] = true
                            }
                        })
                    }

                    const button_classes = {}
                    if(date.isSame(today, "day")) button_classes["date-today"] = true

                    const disabled = (min && date.isBefore(min)) || (max && date.isAfter(max))

                    dates.push({
                        id: this.$dayjs(date).valueOf(),
                        date,
                        cell_classes,
                        button_classes,
                        disabled
                    })
                    date = this.$dayjs(date).add(1, "days")
                }
            }
            return dates
        }
    },
    props: {
        min: { // null, string or native date
            default: null
        },
        max: { // null, string or native date
            default: null
        },
        selection: { // null, [dates or ranges], date or range ; date is native date or string, range is {from: <date>, to: <date>}
            default: null
        },
        size: {
            type: String,
            validator: prop => !!prop && prop.match(/^(sm|md|lg)$/i),
            default: "md"
        }
    },
    data () {
        return {
            current_page_timestamp: null // stores the first day of the month being viewed, **as a timestamp*** so it is reactive
        }
    }
}
</script>

<style lang="scss">
@use "sass:math";
@import "@/assets/sass/variables";

.calendar {
    .calendar-body {
        background-color: $body-bg;
    }

    &.calendar-sm {
        .calendar-nav {
            padding: $layout-spacing-sm;
        }

        .calendar-header,
        .calendar-body {
            padding: $layout-spacing-sm 0;
        }

        .calendar-date {
            padding: math.div($unit-1, 2);
        }
    }
}
</style>
