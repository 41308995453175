<template>
    <div
        :class="{ popover: !!position, [`popover-${position}`]: true, active: picking }"
        class="base-date-input hover-disabled"
    >
        <input-group
            glyph="calendar"
            glyph-position="right"
            @click="click_icon"
        >
            <template v-slot:left_addon><slot name="left_addon"></slot></template>
            <base-input
                ref="field"
                :id="identifier"
                type="text"
                :name="name"
                :autocomplete="name"
                :placeholder="placeholder"
                :value="display_value"
                :aria-describedby="!!has_slot('hint')?`${identifier}-description`:false"
                :disabled="disabled"
                :required="required"
                @focus="focused"
                @touchstart="touchstart"
                @keyup="$emit('keyup', $event)"
                @keydown="keydown"
                @blur="blurred"
            />
        </input-group>
        <div
            v-if="!!position"
            ref="calendar"
            class="popover-container"
        >
            <base-calendar
                :min="min"
                :max="max"
                :selection="value"
                :size="size"
                @click="pick"
            />
        </div>
    </div>
</template>

<script>
import debounce from "lodash/debounce"

import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_alpha_numerical_input from "@/nibnut/mixins/IsAlphaNumericalInput"
import handles_dates from "@/nibnut/mixins/HandlesDates"

import InputGroup from "@/nibnut/components/Inputs/InputGroup"
import BaseInput from "@/nibnut/components/Inputs/BaseInput"
import BaseCalendar from "@/nibnut/components/Inputs/BaseCalendar"

const parse = require("parse-messy-time")

export default {
    name: "BaseDateInput",
    mixins: [is_nibnut_component, is_alpha_numerical_input, handles_dates],
    components: {
        InputGroup,
        BaseInput,
        BaseCalendar
    },
    watch: {
        editable: "maybe_focus_field"
    },
    methods: {
        focus_field () {
            this.$refs.field.$el.focus()
            // this.$refs.field.$el.select()
        },
        maybe_focus_field () {
            if(this.editable) setTimeout(this.focus_field, 150)
        },
        focused () {
            this.$refs.field.$el.select()
            this.picking = true
            this.safari_event_count = 0
        },
        keydown: debounce(function () {
            if(this.alphanumeric && this.iOS) this.touching = false
        }, 300),
        blurred (event) {
            if(this.alphanumeric && this.iOS) this.touching = false

            const is_calendar_clicked = !!event.relatedTarget && !!this.$refs.calendar && this.$refs.calendar.contains(event.relatedTarget)
            const go_on = () => {
                if(this.iOS && !this.safari_event_count) return
                this.safari_event_count = 0
                let selected_date = null
                try {
                    if(event.target.value) {
                        if(event.target.value.match(/^\d{1,4}.+\d{1,4}.+\d{1,4}$/)) {
                            selected_date = this.$dayjs(event.target.value)
                        } else {
                            selected_date = parse(event.target.value)
                            if(isNaN(selected_date.getTime())) selected_date = null
                        }
                    }
                } catch (error) {
                    selected_date = null
                }
                if(!selected_date || (this.$dayjs(this.standardized_date(this.value)).format(this.dateFormat) !== this.$dayjs(selected_date).format(this.dateFormat))) this.$emit("input", selected_date, this.name)
                this.picking = false
            }
            if(!is_calendar_clicked) {
                if(this.iOS && !this.safari_event_count) {
                    this.safari_event_count = 1
                    this.$nextTick(go_on) // Safari does the blur before the click, so we need to wait a little
                } else go_on()
            }
        },
        click_icon () {
            this.focus_field()
        },
        pick (date) {
            this.$emit("input", date, this.name)
            if(this.closeOnClick) this.picking = false
            this.safari_event_count = 0
        }
    },
    computed: {
        display_value () {
            if(!this.value) return ""
            return this.standardized_date(this.value).format(this.dateFormat)
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        placeholder: {
            type: String,
            default: ""
        },
        value: {
            default: ""
        },
        dateFormat: {
            type: String,
            default: "MM/DD/YYYY"
        },
        position: { // use "over" for dialogs, where the popup spills out: "over" will place the calendar popup "over" the field/trigger
            validator: prop => {
                return !prop || (typeof prop === "string") || !!prop.match(/^(top|bottom|left|right|over)$/i)
            },
            default: "top"
        },
        min: { // null, string or native date
            default: null
        },
        max: { // null, string or native date
            default: null
        },
        size: {
            type: String,
            validator: prop => !!prop && prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        closeOnClick: {
            type: Boolean,
            default: true
        },
        editable: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            picking: false,
            safari_event_count: 0
        }
    }
}
</script>

<style lang="scss">
.base-date-input {
    width: 100%;
}
</style>
