import { mapState } from "vuex"

const misc_utilities = {
    methods: {
        setting (id) {
            if(this.app_context && this.app_context.settings) return this.app_context.settings[id]
            return null
        },
        constants (group, constant = null) {
            if(!!this.app_context && !!this.app_context.constants && !!this.app_context.constants[group]) {
                if(!constant) return this.app_context.constants[group]
                return this.app_context.constants[group][constant] || {}
            }
            return {}
        },
        constant (group, id) {
            if(!!this.app_context && !!this.app_context.constants && !!this.app_context.constants[group]) {
                return Object.values(this.app_context.constants[group]).find(constant => constant.id === id) || {}
            }
            return {}
        },
        constant_options (group) {
            return Object.values(this.constants(group))
        }
    },
    computed: {
        ...mapState(["app_context"])
    }
}

export default misc_utilities
