<template>
    <div
        :id="id"
        :class="{ [`nibnut-editor-${size}`]: true }"
        class="nibnut-editor"
    >
        <vue-trix
            ref="editor"
            :value="value"
            :input-name="name"
            :placeholder="placeholder"
            :disabled-editor="!editable"
            @trix-initialize="initialize"
            @input="debounced_emit_input"
        />
    </div>
</template>

<script type="text/javascript">
import debounce from "lodash/debounce"

import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_alpha_numerical_input from "@/nibnut/mixins/IsAlphaNumericalInput"

import VueTrix from "vue-trix"

export default {
    name: "BaseEditor",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        VueTrix
    },
    mounted () {
        this.reseed()
    },
    watch: {
        dataVersion: "reseed"
    },
    methods: {
        initialize () {},
        reseed () {
            this.editor_html = this.value
            if(!!this.$refs.editor && (this.$refs.editor.$refs.trix.innerHTML !== this.editor_html)) {
                this.$refs.editor.$refs.trix.editor.loadHTML(this.editor_html)
            }
        },
        emit_input (event) {
            this.$emit("input", event, this.name)
        },
        debounced_emit_input: debounce(function (event) {
            this.emit_input(event)
        }, 300)
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        dataVersion: {
            required: true
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(sm|md|lg|full)$/i),
            default: "md"
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            editor_html: ""
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-editor {
    &.nibnut-editor-sm trix-editor {
        min-height: 1rem;
    }
    &.nibnut-editor-lg trix-editor {
        min-height: 10rem;
    }
    &.nibnut-editor-full {
        &, & > div, trix-editor {
            height: 100%;
        }
    }
    trix-toolbar {
        .trix-button-group:not(:first-child) {
            margin-left: 0.5rem;
        }
        .trix-button-group.trix-button-group--file-tools { display: none; }
    }
}
</style>
