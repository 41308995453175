/*
<prompt
    v-if="prompting"
    v-bind="prompt_props"
    @cancel="done_prompting"
    @save="..."
/>
*/

const prompts = {
    components: {
        Prompt: () => import("@/nibnut/components/ModalDialog/ModalPrompt")
    },
    methods: {
        prompt (props, id = true) {
            this.prompt_props = {
                dismissable: false,
                title: "",
                message: "",
                ...props
            }
            this.prompting = id
        },
        set_prompt_props (props = {}) {
            Object.keys(props).forEach(prop => {
                this.$set(this.prompt_props, prop, props[prop])
            })
        },
        done_prompting () {
            this.prompting = false
            this.prompt_props = {}
        }
    },
    data () {
        return {
            prompting: false, // true or confirmation ID
            prompt_props: {}
        }
    }
}

export default prompts
